import {
    useState,
    useContext,
    ReactNode,
    FC,
    createContext,
    useEffect,
} from 'react';
import { useWebsocketContext } from './websocket-context';
import {
    BaseEvents,
    ChatMessageEvent,
    createSendChatMessageEvent,
} from '@shared/events';

export type ChatContextProviderProps = {
    children: ReactNode;
    messageLimit?: number;
};

export type ChatContextValue = {
    messages: ChatMessageEvent[];
    sendChatMessage: (message: string) => void;
    hasNewMessages: boolean;
    setHasNewMessages: (value: boolean) => void;
};

export const ChatContext = createContext<ChatContextValue | undefined>(
    undefined
);

export const ChatContextProvider: FC<ChatContextProviderProps> = (props) => {
    const { children, messageLimit = 200 } = props;
    const { socket } = useWebsocketContext();

    const [messages, setMessages] = useState<ChatMessageEvent[]>([]);
    const [hasNewMessages, setHasNewMessages] = useState<boolean>(false);

    useEffect(() => {
        socket.on(BaseEvents.ChatMessage, (message: ChatMessageEvent) => {
            setMessages((prev) => {
                const slicedMessages =
                    prev.length > messageLimit
                        ? prev.slice(0, messageLimit)
                        : prev;

                return [message, ...slicedMessages];
            });
            setHasNewMessages(true);

            setTimeout(() => setHasNewMessages(false), 5000);
        });

        return () => {
            socket.off(BaseEvents.ChatMessage);
        };
    }, [socket, messageLimit]);

    const sendChatMessage = (message: string) => {
        socket.emit(...createSendChatMessageEvent(message));
    };

    return (
        <ChatContext.Provider
            value={{
                messages,
                sendChatMessage,
                hasNewMessages,
                setHasNewMessages,
            }}
        >
            {children}
        </ChatContext.Provider>
    );
};

export const ChatContextConsumer = ChatContext.Consumer;
export const useChatContext = () => {
    const context = useContext(ChatContext);

    if (context === undefined) {
        throw new Error(
            'ChatContext.Consumer must be used within a ChatContext.Provider'
        );
    }

    return context;
};
