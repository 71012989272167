import { AspectRatioKeeper } from '@shared/ui/components/generic';
import { WebSocketProvider, ChatContextProvider } from '@shared/ui/contexts';

import { ChatLayout } from './layouts/app-layout';

const createSession = () => {
    if (process.env.NODE_ENV === 'development') {
        const data = {
            type: 'service',
            name: 'Dealer Chat',
            service: 'dealer-chat',
        };

        return btoa(JSON.stringify(data));
    }
    return undefined;
};

const url = new URLSearchParams(window.location.search);
const token = url.get('token') || undefined;

const sessionId = createSession();

export const App = () => {
    if (!token && !sessionId) return <div>Invalid token</div>;

    return (
        <WebSocketProvider
            token={token}
            sessionId={sessionId}
            useTokenHost={!!token}
        >
            <ChatContextProvider messageLimit={50}>
                <AspectRatioKeeper isPortrait={true}>
                    <ChatLayout />
                </AspectRatioKeeper>
            </ChatContextProvider>
        </WebSocketProvider>
    );
};
